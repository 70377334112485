<template>
  <div class="wrapper">
    <div class="animated fadeIn">

      <BRow>
        <BCol>
          <BCard header-tag="header">
            <div slot="header">
              <strong><BIconPersonCheckFill/> 사용자 정보 설정</strong>
              <div class="card-header-actions">
                <small class="text-muted"><strong class="alert-danger">{{user.userId}}</strong>의 사용자 정보를 설정 합니다</small>
              </div>
            </div>
            <BForm @submit="userSubmit">
              <BRow>
                <BCol>
                  <BFormGroup class="mb-1" id="form-01" label="ID" label-cols="2" label-for="input-id" label-align="right">
                    <BFormInput id="input-id"
                                v-model="user.userId"
                                type="text"
                                readonly/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-010" label="이름" label-cols="2" label-for="display-name" label-align="right">
                    <BFormInput id="display-name"
                                v-model="user.name"
                                type="text"
                                placeholder="이름을 입력 하세요"
                                required/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-11" label="권한" label-cols="2" label-for="input-user-role" label-align="right">
                    <BFormSelect id="input-user-role"
                                 v-model="user.role"
                                 :options="roleOpts"
                                 :disabled="user.role!=='A'"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-06" label="표시명" label-cols="2" label-for="input-user-nick" label-align="right">
                    <BFormInput id="input-user-nick"
                                v-model="user.nickName"
                                type="text"
                                placeholder="표시명 명 입력"/>
                  </BFormGroup>

                  <BFormGroup class="mb-1" id="form-07" label="전화번호" label-cols="2" label-for="input-phone-no" label-align="right">
                    <BFormInput id="input-phone-no"
                                type="text"
                                v-model="user.phoneNo"
                                placeholder="전화 번호 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-071" label="내선번호" label-cols="2" label-for="input-phone-int" label-align="right">
                    <BFormInput id="input-phone-int"
                                type="text"
                                v-model="user.intPhoneNo"
                                placeholder="내선 전화 번호 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-072" label="대표번호" label-cols="2" label-for="input-phone-ext" label-align="right">
                    <BFormInput id="input-phone-ext"
                                type="text"
                                v-model="user.extPhoneNo"
                                placeholder="대표 전화 번호 입력"/>
                  </BFormGroup>

                  <BFormGroup class="mb-1" id="form-08" label="이메일" label-cols="2" label-for="input-email" label-align="right">
                    <BFormInput id="input-email"
                                v-model="user.email"
                                type="text"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-09" label="센터" label-cols="2" label-for="input-center" label-align="right">
                    <BFormSelect id="input-center"
                                 v-model="user.center"
                                 :options="centerOpts"
                                 :style="{ backgroundColor: '#24252F', color: 'white' }"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-091" label="그룹" label-cols="2" label-for="input-work" label-align="right">
                    <BFormSelect id="input-work"
                                 v-model="user.workgroup"
                                 :options="wgOpts"
                                 :style="{ backgroundColor: '#24252F', color: 'white' }"/>
                  </BFormGroup>


                  <BRow>
                    <BCol class="text-right">
                      <b-button type="submit" variant="primary"
                                :disabled="user.userId===''">저 장</b-button>
                    </BCol>
                  </BRow>
                </BCol>
              </BRow>
            </BForm>
          </BCard>

          <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
            <div slot="header">
              <strong><BIconLockFill/> 비밀번호 설정</strong>
              <div class="card-header-actions">
                <small class="text-muted"><strong class="alert-danger">{{user.userId}}</strong>의 비밀번호를 설정 합니다.</small>
              </div>
            </div>


            <BRow >
              <BCol>
                <BFormInput class="mt-3" v-model="currPwd"  type="password" placeholder="현재 비밀번호를 입력하세요"/>
                <BFormInput class="mt-3" v-model="newPwd"  :state="validPwdState"  max="20" type="password" placeholder="비밀번호는 영문.숫자.특수문자를 포함해야 하며 최소8자 이상입니다" />
                <BFormInput class="mt-2" v-model="rePwd" :state="validPwdConfirm" max="20" type="password" placeholder="비밀번호를 다시 입력해주새요"/>

              </BCol>
            </BRow>

            <BRow class="mt-3" align-h="between">
              <BCol>

              </BCol>
              <BCol class="text-right">
                <BButton  variant="danger" @click="setUserPwd" :disabled="!isPwdChecked">비밀번호 변경</BButton>
              </BCol>
            </BRow>
          </BCard>

        </BCol>
        <BCol>

          <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
            <div slot="header">
              <strong><BIconMap/> 사용자 주소 설정</strong>
              <div class="card-header-actions">
                <small class="text-muted"><strong class="alert-danger">{{user.userId}}</strong>의 주소를 설정 합니다.</small>
              </div>
            </div>

            <BRow class="border-info p-2 m-2 small">
              <BCol>
                <BFormGroup class="mb-1" id="form-addr" label="주소" label-cols="2" label-for="input-addr" label-align="right">
                  <BFormInput id="input-addr"
                              v-model="user.addr"
                              type="text"
                              placeholder="" disabled/>
                </BFormGroup>

                <BFormGroup class="mb-1" id="form-addr-dtl" label="상세 주소" label-cols="2" label-for="input-addr-dtl" label-align="right">
                  <BFormInput id="input-addr-dtl"
                              v-model="user.addrDtl"
                              type="text"
                              placeholder="" disabled/>
                </BFormGroup>
                <BFormGroup class="mb-1" id="form-addr-xy" label="경도,위도" label-cols="2" label-for="input-addr-xy" label-align="right">
                  <BFormInput id="input-addr-xy" size="sm"
                              v-model="user.addrXy"
                              type="text"
                              placeholder="" disabled/>
                </BFormGroup>
              </BCol>
            </BRow>




            <addr-search label-type="user"
                         class="mt-3 small"
                         ref="userAddr"
                         :addr="user.addr"
                         :addr-dtl="user.addrDtl"
                         @selected="(e)=>setUserAddr(e)"/>

            <BRow>
              <BCol class="text-right">
                <b-button type="button" variant="info"
                          @click="saveUserAddr"
                          :disabled="!user.addr">주소 저장</b-button>
              </BCol>
            </BRow>

          </BCard>




        </BCol>
      </BRow>
    </div>
  </div>
</template>

<script>
//-------------------------------------------------------------------------------------------------
// import moment from 'moment';
import {
  alertModal,
  alertSuccess,
  alertError,
  apiCall,
  apiMultiPart,
  cloneVar,
  confirmModal,
  getNotice, alertWarn, alertConfirm
} from '@/common/utils';
// import qs from 'querystring';
import moment from "moment";
import AddrSearch from '../components/AddrSearch.vue'

const _user = {
  userId      : '',
  name        : '',
  nickName    : '',
  role        : '',
  user        : '',
  center      : '',
  avatar      : '',
  position    : '',
  email       : '',
  phoneNo     : '',
  intPhoneNo  : '',
  extPhoneNo  : '',
  addr        : '', // 출발지 주소
  addrDtl     : '', // 출발지 주소 상세
  addrXy      : '',
  regionAddr  : {
    rga:   '', // 구주소 전체
    rg1:   '', // 구주소 시도
    rg2:   '', // 구주소 구군
    rg3:   '', // 구주소 동면
    mNo:   '', // main 번지수
    sNo:   '', // 서브 번지수
  },
  status      : '',
  workgroup   : '',
  wgCode      : '',
  workStatus  : '',
  taskAllows  : '',
  isDeleted   : '',
  isApproved  : '',
  approvedAt  : '',
  approveId   : '',
  remark      : '',
};

//----------------------------------------------------------------------------------------------------
export default {
  name: 'UserInfo',
  components: { AddrSearch },
  data () {
    return {
      user: null,
      currPwd: '',
      newPwd: '',
      rePwd: '',
      centerOpts: [],
      wgOpts: [],
      roleOpts: [
        {value: 'S', text:'수퍼바이저'},
        {value: 'D', text:'딜러'},
        {value: 'T', text:'상담사'},
      ],
      counselorId: null,

      actionMode: 'put',

      isPwdChecked: false,
      pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합

    }
  },
  computed: {
    validPwdState(){
      let ret = false;
      // const p2 = this.newPwdConfirm;
      if( this.newPwd.length > 7 ){
        if( this.pwdRegexp.test( this.newPwd )) ret = true;
      }
      return ret;
    },
    validPwdConfirm(){
      let ret = false;
      const p1 = this.newPwd;
      const p2 = this.rePwd;
      if( p2.length > 7 && p1 === p2 ){
        ret = true;
        this.isPwdChecked = true;
      }else{
        this.isPwdChecked = false;
      }
      return ret;
    },
  },

  async created(){
    try{
      this.user = cloneVar(_user);
      this.counselorId = this.$store.state.user.loginUserId;

      await this.getCenters();
      await this.getUserInfo();
    }catch(err){
      console.log(err);
    }

  },


  methods: {

    async userSubmit(evt){
      evt.preventDefault();

      await this.saveUser();
    },


    async saveUser() {
      let r = null;

      try{
        r = await apiCall("PUT", `/api/counselor/${this.counselorId}`, this.user);
        if( r.code===200 ){
          await alertSuccess(this.$bvModal, '사용자 정보가 저장 되었습니다.');
        }else{
          await alertError(this.$bvModal, '사용자 정보저장 실패: '+r.message);
        }

      }catch(err){
        console.log( err );
        alert(err.message);
      }
    },

    async saveUserAddr() {
      let r = null;

      try{
        r = await apiCall("PUT", `/api/counselor/addr/${this.counselorId}`, this.user);
        if( r.code===200 ){
          await alertSuccess(this.$bvModal, '사용자 주소 정보가 저장 되었습니다. 재 로그인 후 적용됩니다..');
        }else{
          await alertError(this.$bvModal, '사용자 주소 정보저장 실패: ' + r.message);
        }

      }catch(err){
        console.log( err );
        alert(err.message);
      }
    },

    setUserAddr(e=null){
      console.log('set user address--->', e);
      this.user.addr = e.base;
      this.user.addrDtl = e.dtl;
      this.user.addrXy = e.xy;
      this.user.regionAddr = e.region;

      console.log('setUserAddr --- user --->', this.user)
    },

    async getCenters(){
      console.log("======================getCenters========================");
      let r = null;
      try{
        this.centerOpts = [];
        this.wgOpts = [];
        r = await apiCall('get', `/api/counselor/centers`);
        console.log("result--------->", r);
        if( r.code===200){
          r.result.centers.map(i=>{
            this.centerOpts.push({value: i._id, text: `${i.centerCode}-${i.centerName}`})
          });

          r.result.workgroups.map(i=>{
            this.wgOpts.push({value: i._id, text: `${i.code}-${i.name}` });

          });
        }

      }catch(err){
        console.log( err );
      }
    },


    async getUserInfo(){
      console.log("======================getUserInfo========================");
      let r = null;
      try{
        r = await apiCall('get', `/api/counselor/${this.counselorId}`);
        console.log("result--------->", r);
        if( r.code===200){
          this.user = r.result;
        }

      }catch(err){
        console.log( err );
      }
    },

    async setUserPwd(){
      console.log( "setUserPwd ---> ");
      try{

        const msg = `${this.user.userId}의 비밀번호를 변경 합니다.`
          +` 변경 후 로그아웃 됩니다. 진행 하시겠습니까?`;
        if( !(await alertConfirm(this.$bvModal, msg, '비밀번호 변경')) )
          return;

        const param = {
          currPwd: this.currPwd,
          newPwd : this.newPwd,
          rePwd : this.rePwd
        };
        const r = await apiCall('post', `/api/auth/pwd-change`, param);
        if(r.result){
          await alertSuccess(this.$bvModal, "비밀번호가 변경 되었습니다", "Success");
          await this.$store.dispatch('LOGOUT');
          setTimeout( async ()=>await this.$router.push({ path: '/login'  }), 500 );
        }else{
          await alertWarn( this.$bvModal, '비밀번호 변경 실패: '+ r.message, 'Error:' + r.code );
          // alert('관리자에게 문의하시기 바랍니다');
        }

      }catch(err){
        console.log( err );
        await alertWarn( this.$bvModal, 'ERROR: ' + err.message, '에러' );
      }
    },

  }
}
</script>
